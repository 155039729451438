<template>
  <div :class="cssclass">
    <Grid
      ref="grid"
      :additional-api-arguments="apiArgs"
      :enable-pagination="true"
      :enable-history="!actionRunning"
      :default-sort-by="defaultSortBy"
      :default-sort-desc="defaultSortDesc"
      :pagination-show-first-last-page="paginationShowFirstLastPage"
      :pagination-show-totals="paginationShowTotals"
      :enabled-columns="columnSwitchOptions.activeColumns"
      :headers="headers"
      :single-select="false"
      :show-select="true"
      :item-class="itemClass"
      data-store-action="domainstats/fetchData"
      form-name="domain-stats-form"
      item-key="domainidn"
    >
      <template v-slot:middle v-if="showOnlyPerformingHint">
        <v-card class="mb-5 only-performing-hint">
          <v-banner single-line>{{ $t('domain_index.none_performing_note') }}</v-banner>
        </v-card>
      </template>
      <!-- filter slot with additional filter -->
      <template v-slot:filters>
        <v-col class="col-12 col-sm-6 col-md-2 filter-search">
          <v-text-field
            :label="$t('domain_index.search')"
            :value="apiArgs.restarting.search.value"
            dense
            filled
            hide-details
            placeholder=""
            @change="apiArgs.restarting.search.value = $event"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col class="col-12 col-sm-6 col-md-2 filter-folder">
          <FolderSelector
            :add-item-to-reset-filter="true"
            :item-value="apiArgs.restarting.folder.value"
            @update_folder="apiArgs.restarting.folder.value = $event"
          />
        </v-col>
        <v-col class="col-12 col-sm-6 col-md-2 filter-statstype">
          <v-select
            :items="availableStatsTypes"
            label="Time Period"
            :value="apiArgs.restarting.stats_type.value"
            @change="apiArgs.restarting.stats_type.value = $event"
            :menu-props="{ contentClass: 'filter-statstype-menu', bottom: true, offsetY: true }"
            dense
            filled
            hide-details
            item-text="text"
            item-value="value"
            id="filter-statstype-select"
            prepend-inner-icon="mdi-calendar-blank"
          >
          </v-select>
        </v-col>
        <v-col class="col-12 col-sm-6 col-md-2 filter-column-presets">
          <v-select
            :items="columnSwitchOptions.available"
            :label="$t('domain_index.columns')"
            :menu-props="{ contentClass: 'filter-column-presets-menu', bottom: true, offsetY: true }"
            :value="columnSwitchOptions.activeColumns"
            dense
            filled
            hide-details
            item-text="text"
            item-value="columns"
            id="filter-column-presets-select"
            @change="columnSwitchOptions.activeColumns = $event"
            prepend-inner-icon="mdi-view-column-outline"
          ></v-select>
        </v-col>
        <v-col class="col-12 col-sm-4 col-md-4 more-filters text-right">
          <MoreFiltersButton class="mt-2" @openMoreFiltersDialog="onOpenMoreFiltersDialog" :number-of-selected-filters="numberOfSelectedFilters" />
        </v-col>
      </template>
      <template v-slot:toolbarSelect v-if="getGridHasResults()">
        <div class="toolbar-select d-inline-flex">
          <v-simple-checkbox v-model="$refs.grid.isBulkEnabled" class="px-2 bulk-checkbox" color="primary" :ripple="false" />
          <span class="pt-2">{{ $t('actions.all_pages') }}</span>
          <div v-if="$refs.grid.isBulkEnabled || $refs.grid.selected.length" class="d-inline-flex">
            <span class="toolbar-select-count px-6 pt-2" v-if="$refs.grid.isBulkEnabled">
              {{ $tc('domain_index.domain_selected', $refs.grid.data.total) }}
            </span>
            <span class="toolbar-select-count px-6 pt-2" v-else>{{ $tc('domain_index.domain_selected', $refs.grid.selected.length) }}</span>
          </div>
        </div>
        <div v-if="$refs.grid.isBulkEnabled || $refs.grid.selected.length" class="toolbar-select-actions d-inline-flex">
          <ActionButton :description="$t('actions.edit_domains')" icon="mdi-pencil-outline" @click="openEditPage($refs.grid.selected)" />
          <ActionButton
            :description="$t('actions.move_folder')"
            icon="mdi-folder-move-outline"
            @click="openActionDialog($refs.grid.selected, 'showMoveToFolderDialog')"
          />
          <ActionButton
            :description="$t('actions.delete_domains')"
            icon="mdi-delete-outline"
            @click="openActionDialog($refs.grid.selected, 'showDeleteDomainDialog')"
          />
        </div>
      </template>
      <template v-slot:[`item.domainidn`]="{ item }">
        <router-link :to="'/stats/domain/date/' + item.domain">
          <span class="domain column-domainidn">{{ item.domainidn }}</span>
        </router-link>
      </template>
      <template v-slot:[`item.folder`]="{ item }">
        <span class="folder column-folder">{{ item.folder === 1 ? 'Default' : getFolderName(item.folder) }}</span>
      </template>
      <template v-slot:[`item.revenue`]="{ item }">
        <span class="revenue">{{ $f.price(item.revenue, 'USD', true) }}</span>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        {{ $f.price(item.price, 'USD', true) }}
      </template>
      <template v-slot:[`item.years`]="{ item }">
        {{ $f.number(item.years, 0, 0) }}
      </template>
      <template v-slot:[`item.profit`]="{ item }">
        {{ $f.price(item.profit, 'USD', true) }}
      </template>
      <template v-slot:[`item.clicks`]="{ item }"
        ><span class="clicks">{{ $f.number(item.clicks, 0, 0) }}</span></template
      >
      <template v-slot:[`item.uniques`]="{ item }"
        ><span class="uniques">{{ $f.number(item.uniques, 0, 0) }}</span></template
      >
      <template v-slot:[`item.ctr`]="{ item }"
        ><span class="ctr">{{ $f.number(item.ctr) }}%</span></template
      >
      <template v-slot:[`item.rpc`]="{ item }">
        <span class="rpc">{{ $f.price(item.rpc, 'USD', true) }}</span>
      </template>
      <template v-slot:[`item.rpm`]="{ item }">
        <span class="rpm">{{ $f.price(item.rpm, 'USD', true) }}</span>
      </template>
      <template v-slot:[`item.forsale_linkstyle`]="{ item }">
        <Crop max-width="100px" :value="getBannerNameById(item.forsale_linkstyle)" />
      </template>
      <template v-slot:[`item.forsale_template`]="{ item }">
        <Crop max-width="100px" :value="getSaleTemplateNameById(item.forsale_template)" />
      </template>
      <template v-slot:[`item.custom_text`]="{ item }">
        <Crop max-width="80px" :value="getSaleTextById(item.custom_text)" />
      </template>
      <template v-slot:[`item.sellurl_name`]="{ item }">
        <Crop max-width="100px" :value="getSaleUrlNameById(item.sellurl)" />
      </template>
      <template v-slot:[`item.sellurl_url`]="{ item }">
        <Crop max-width="100px" :value="getSaleUrlLinkById(item.sellurl)" />
      </template>
      <template v-slot:[`item.forsale_type`]="{ item }">
        <span class="forsale_type">{{ $t('domain_index.forsale_type_' + item.forsale_type) }}</span>
      </template>
      <template v-slot:[`item.min_offer`]="{ item }">
        <span class="min_offer">{{ $f.price(item.min_offer, item.currency) }}</span>
      </template>
      <template v-slot:[`item.captcha`]="{ item }">
        <span class="captcha">{{ $f.boolean(item.captcha) }}</span>
      </template>
      <template v-slot:[`item.datefirst`]="{ item }">
        <span class="datefirst">{{ $f.shortDate(item.datefirst) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="column-actions">
          <ActionMenu>
            <template v-slot:actionMenuItems>
              <ActionMenuItem
                :text="$t('actions.preview_page')"
                icon="mdi-feature-search-outline"
                :href="getDomainURL(item.domainidn)"
                target="_blank"
                class="preview-page"
              />
              <ActionMenuItem :text="$t('actions.edit_domain')" icon="mdi-pencil-outline" @click="openEditPage([item])" class="domain-edit" />
              <ActionMenuItem
                :text="$t('actions.move_folder')"
                icon="mdi-folder-move-outline"
                @click="openActionDialog([item], 'showMoveToFolderDialog')"
                class="domain-move"
              />
              <ActionMenuItem
                :text="$t('actions.delete_domain')"
                icon="mdi-delete-outline"
                @click="openActionDialog([item], 'showDeleteDomainDialog')"
                class="domain-delete"
              />
            </template>
          </ActionMenu>
        </div>
      </template>
    </Grid>

    <ActionDialog
      mode="move"
      :show-dialog="showMoveToFolderDialog"
      :title="getDialogTitle($t('misc.move'))"
      :item-value="moveToFolderId"
      @dialog:close="closeActionDialog('showMoveToFolderDialog')"
      @dialog:confirmed="moveToFolder"
      :waiting-for-end-of-action="actionRunning"
      @update_folder="moveToFolderId = $event"
    />

    <ActionDialog
      mode="delete"
      :show-dialog="showDeleteDomainDialog"
      :title="getDialogTitle($t('misc.delete'))"
      :text="$t('domain_index.domain_delete_info')"
      @dialog:close="closeActionDialog('showDeleteDomainDialog')"
      @dialog:confirmed="deleteDomain"
      :waiting-for-end-of-action="actionRunning"
    />

    <MoreFiltersDialog
      :show-dialog="showMoreFiltersDialog"
      @closeDialog="onCloseMoreFiltersDialog"
      :config="moreFiltersDialogConfig"
      @applyFilters="onApplyFilters"
      @removeFilter="onRemoveFilter"
      @clearFilters="onClearFilters"
    />
  </div>
</template>

<script>
import FolderSelector from '@/components/folder/FolderSelector'
import Grid from '@/components/grid/Grid'
import GridBaseMixin from '@/components/grid/GridBaseMixin'
import DateHelper from '@/util/DateHelper'
import Crop from '@/components/others/Crop'
import ReferenceResolverMixin from '@/components/grid/ReferenceResolverMixin'
import DomainListMixin from '@/components/domain/DomainListMixin'
import MoreFiltersMixin from '@/components/others/mixins/MoreFiltersMixin'
import ActionButton from '@/components/app/ActionButton'
import ActionDialog from '@/components/others/ActionDialog'
import ActionMenuItem from '@/components/app/ActionMenuItem'
import ActionMenu from '@/components/app/ActionMenu'

export default {
  mixins: [GridBaseMixin, ReferenceResolverMixin, DomainListMixin, MoreFiltersMixin],
  components: {
    ActionMenu,
    ActionMenuItem,
    ActionDialog,
    ActionButton,
    FolderSelector,
    Grid,
    Crop
  },
  data() {
    let parkingColumnSet = {
      text: 'Parking',
      columns: ['domainidn', 'folder', 'revenue', 'clicks', 'uniques', 'ctr', 'rpc', 'rpm', 'datefirst', 'actions']
    }
    let salesColumnSet = {
      text: 'Sale',
      columns: [
        'domainidn',
        'folder',
        'forsale_type',
        'forsale_linkstyle',
        'forsale_template',
        'custom_text',
        'sellurl_name',
        'sellurl_url',
        'min_offer',
        'captcha',
        'datefirst',
        'revenue',
        'actions'
      ]
    }
    const defaultStatsTypes = [
      { text: 'All-time', value: 'total' },
      { text: 'Today', value: 'today' },
      { text: 'Yesterday', value: 'yesterday' },
      { text: 'Current week', value: 'currentweek' },
      { text: 'Last week', value: 'lastweek' },
      { text: 'Current month', value: 'currentmonth' },
      { text: 'Last month', value: 'lastmonth' },
      { text: 'Current year', value: 'currentyear' },
      { text: 'Last year', value: 'lastyear' }
    ]
    return {
      moreFiltersDialogConfig: [
        {
          label: this.$t('domain_index.revenue'),
          type: 'stats',
          apiArgsField: 'revenue'
        },
        {
          label: this.$t('domain_index.uniques'),
          type: 'stats',
          apiArgsField: 'uniques'
        },
        {
          label: this.$t('domain_index.clicks'),
          type: 'stats',
          apiArgsField: 'clicks'
        },
        {
          label: this.$t('domain_index.rpm'),
          type: 'stats',
          apiArgsField: 'rpm'
        },
        {
          label: this.$t('domain_index.rpc'),
          type: 'stats',
          apiArgsField: 'rpc'
        },
        {
          label: this.$t('domain_index.ctr'),
          type: 'stats',
          apiArgsField: 'ctr'
        },
        {
          label: 'Divider'
        },
        {
          label: this.$t('domain_index.sale_type'),
          apiArgsField: 'forsale_type',
          source: 'saleTypes',
          type: 'select',
          itemValue: 'value',
          itemText: 'text'
        },
        {
          label: this.$t('domain_index.sale_banner'),
          apiArgsField: 'forsale_linkstyle',
          source: 'saleBanners',
          type: 'select',
          itemValue: 'id',
          itemText: 'name'
        },
        {
          label: this.$t('domain_index.sellurl'),
          apiArgsField: 'sellurl',
          source: 'saleUrls',
          type: 'select',
          itemValue: 'id',
          itemText: 'name'
        },
        {
          label: this.$t('domain_index.custom_text'),
          apiArgsField: 'custom_text',
          source: 'saleTexts',
          type: 'select',
          itemValue: 'id',
          itemText: 'text'
        },
        {
          label: 'Divider'
        },
        {
          label: this.$t('domain_index.tld'),
          apiArgsField: 'tld',
          type: 'search'
        },
        {
          label: this.$t('domain_index.is_deleted'),
          apiArgsField: 'is_deleted',
          type: 'boolean',
          trueValue: 1,
          falseValue: 0,
          defaultValue: 0
        },
        {
          label: this.$t('domain_index.imprint'),
          apiArgsField: 'useimprint',
          type: 'boolean',
          trueValue: 1,
          falseValue: 0,
          defaultValue: 0
        },
        {
          label: this.$t('domain_index.direct_advertiser'),
          apiArgsField: 'directadv',
          type: 'boolean',
          trueValue: 1,
          falseValue: 0,
          defaultValue: 0
        },
        {
          label: this.$t('domain_index.show_domain_name'),
          apiArgsField: 'show_domainname',
          type: 'boolean',
          trueValue: 'yes',
          falseValue: 'no',
          defaultValue: 'no'
        }
      ],
      showMoveToFolderDialog: false,
      showDeleteDomainDialog: false,
      showOnlyPerformingHint: false,
      paginationShowFirstLastPage: true,
      paginationShowTotals: true,
      userFolder: [],
      moveToFolderId: null,
      data: {
        total: 0
      },
      defaultSortBy: ['datefirst'],
      defaultSortDesc: [true],
      cssclass: '', // set to 'compact' for smaller rows
      headers: [
        {
          text: '',
          value: 'actions',
          sortable: false
        },
        {
          text: this.$t('domain_index.domain'),
          value: 'domainidn',
          sortable: false
        },
        {
          text: this.$t('domain_index.folder'),
          value: 'folder',
          sortable: false
        },
        {
          text: this.$t('domain_index.sale_type'),
          value: 'forsale_type',
          sortable: false
        },
        { text: this.$t('domain_index.sale_template'), value: 'forsale_template', sortable: false },
        {
          text: this.$t('domain_index.sale_banner'),
          value: 'forsale_linkstyle',
          sortable: false
        },
        {
          text: this.$t('domain_index.sellurl_name'),
          value: 'sellurl_name',
          sortable: false
        },
        {
          text: this.$t('domain_index.sellurl_url'),
          value: 'sellurl_url',
          sortable: false
        },
        {
          text: this.$t('domain_index.custom_text'),
          value: 'custom_text',
          sortable: false
        },
        {
          text: this.$t('domain_index.min_offer'),
          value: 'min_offer',
          sortable: false,
          align: 'right'
        },
        {
          text: this.$t('domain_index.captcha'),
          value: 'captcha',
          sortable: false
        },
        {
          text: this.$t('domain_index.revenue'),
          value: 'revenue',
          sortable: true,
          align: 'right',
          description: this.$t('stats.revenue_description')
        },
        {
          text: this.$t('domain_index.clicks'),
          value: 'clicks',
          sortable: true,
          align: 'right',
          description: this.$t('stats.clicks_description')
        },
        {
          text: this.$t('domain_index.uniques'),
          value: 'uniques',
          sortable: true,
          align: 'right',
          description: this.$t('stats.uniques_description')
        },
        {
          text: this.$t('domain_index.ctr'),
          value: 'ctr',
          sortable: true,
          align: 'right',
          description: this.$t('stats.ctr_description')
        },
        {
          text: this.$t('domain_index.rpc'),
          value: 'rpc',
          sortable: true,
          align: 'right',
          description: this.$t('stats.rpc_description')
        },
        {
          text: this.$t('domain_index.rpm'),
          value: 'rpm',
          sortable: true,
          align: 'right',
          description: this.$t('stats.rpm_description')
        },
        {
          text: this.$t('domain_index.added'),
          value: 'datefirst',
          sortable: true
        }
      ],
      columnSwitchOptions: {
        activeColumns: parkingColumnSet.columns,
        available: [parkingColumnSet, salesColumnSet]
      },
      apiArgs: {
        restarting: {
          folder: this.buildArgument('folder', folder => parseInt(folder) >= 0),
          search: {
            value: this.queryParamOrDefaultValue('search', null),
            check: search => search !== '',
            argumentProvider: (apiArguments, search) => {
              if (search !== '') {
                apiArguments['domain'] = search
              }
            }
          },
          min_revenue: this.buildArgument('min_revenue'),
          max_revenue: this.buildArgument('max_revenue'),
          min_uniques: this.buildArgument('min_uniques'),
          max_uniques: this.buildArgument('max_uniques'),
          min_clicks: this.buildArgument('min_clicks'),
          max_clicks: this.buildArgument('max_clicks'),
          min_rpm: this.buildArgument('min_rpm'),
          max_rpm: this.buildArgument('max_rpm'),
          min_rpc: this.buildArgument('min_rpc'),
          max_rpc: this.buildArgument('max_rpc'),
          min_ctr: this.buildArgument('min_ctr'),
          max_ctr: this.buildArgument('max_ctr'),
          forsale_type: this.buildArgument('forsale_type'),
          forsale_linkstyle: this.buildArgument('forsale_linkstyle', forsale_linkstyle => forsale_linkstyle >= 0),
          sellurl: this.buildArgument('sellurl', sellurl => sellurl >= 0),
          custom_text: this.buildArgument('custom_text', custom_text => custom_text >= 0),
          tld: this.buildArgument('tld'),
          stats_type: {
            value: this.queryParamOrDefaultValue('stats_type', 'total'),
            argumentProvider: (apiArgs, value) => {
              if (value) {
                let typeAndDates = {
                  total: {
                    type: 'total'
                  },
                  today: {
                    type: 'date',
                    date: DateHelper.todayAsString()
                  },
                  yesterday: {
                    type: 'date',
                    date: DateHelper.yesterdayAsString()
                  },
                  currentweek: {
                    type: 'week',
                    date: DateHelper.firstDayOfCurrentWeekAsString()
                  },
                  lastweek: {
                    type: 'week',
                    date: DateHelper.firstDayOfPreviousWeekAsString()
                  },
                  currentmonth: {
                    type: 'month',
                    date: DateHelper.firstDayOfCurrentMonthAsString()
                  },
                  lastmonth: {
                    type: 'month',
                    date: DateHelper.firstDayOfPreviousMonthAsString()
                  },
                  currentyear: {
                    type: 'year',
                    date: DateHelper.firstDayOfYearAsString()
                  },
                  lastyear: {
                    type: 'year',
                    date: DateHelper.firstDayOfPreviousYearAsString()
                  },
                  custom_date: {
                    type: 'date',
                    date: this.customDate
                  }
                }

                apiArgs['stats_type'] = typeAndDates[value]['type']
                if (typeAndDates[value]['date']) {
                  apiArgs['date'] = typeAndDates[value]['date']
                }
              }
            }
          },
          is_deleted: this.buildArgument('is_deleted', is_deleted => is_deleted != null),
          show_query_log: this.buildArgument('show_query_log', show_query_log => show_query_log != null),
          simple_paginator: this.buildArgument('simple_paginator', simple_paginator => simple_paginator != null),
          directadv: this.buildArgument('directadv', directadv => directadv === 0 || directadv === 1),
          useimprint: this.buildArgument('useimprint', useimprint => useimprint === 0 || useimprint === 1),
          show_domainname: this.buildArgument('show_domainname', show_domainname => show_domainname === 'yes' || show_domainname === 'no')
        },
        persistent: {}
      },
      domaindata: {},
      filterPanel: false,
      availableStatsTypes: defaultStatsTypes,
      customDate: null,
      queryParameters: {}
    }
  },
  created() {
    const statsType = this.$route.query.stats_type

    if (statsType === 'custom_date') {
      this.customDate = this.$route.query.custom_date || DateHelper.todayAsString()

      this.availableStatsTypes.unshift({
        text: this.customDate,
        value: statsType
      })
    }
  },
  mounted() {
    this.loadSaleData()
    this.$on('api_data_retrieved', data => {
      this.showOnlyPerformingHint = data.only_performing_domains === 1
      let hasTotal = data.total !== null
      this.paginationShowFirstLastPage = hasTotal
      this.paginationShowTotals = hasTotal
    })
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

tr.inactive-domain
  background: $input-background
</style>
